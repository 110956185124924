import React from "react";
import styled from "styled-components/macro";
import {Colors} from "../styles/Colors";

type Props = {
  title: string;
  items: string[];
};

const BulletList = ({ items, title }: Props) => {
  return (
    <ListSection>
      <StyledBulletList>
        {items.map(item => (
          <BulletItem key={item}>{item}</BulletItem>
        ))}
      </StyledBulletList>
    </ListSection>
  );
};
export default BulletList;
const ListSection = styled.section`
    padding-top: 20px;
    padding-left: 27px;
    color: ${Colors.darkGrey};
    border-left: 2px solid ${Colors.grey};
    margin-left: 11px;

    :first-of-type {
        padding-top: 12px;
    }

`;

const StyledBulletList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding-top: 4px;
  padding-left: 0;
  margin-top: 0;
`;
const BulletItem = styled.li`
  list-style: none;
  :not(:last-of-type) {
    :after {
      margin-left: 8px;
      margin-right: 8px;
      content: "•";
    }
  }
`;
const Title = styled.h3``;
