import Img from "gatsby-image";
import { graphql, StaticQuery } from "gatsby";
import * as React from "react";
import styled from "styled-components/macro";
import {Viewports} from "../styles/Viewports";

export default () => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "Foto_CV.jpg" }) {
          childImageSharp {
            fixed(width: 100, height: 100, toFormat: WEBP) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={(data: any) => (
      <StyledImage fixed={data.file.childImageSharp.fixed} />
    )}
  />
);

const StyledImage = styled(Img)`
    position: absolute !important;
    bottom: -50px;
    border-radius: 50%;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 1);

    ${Viewports.for("small")} {
        position: relative !important;
        bottom: unset;
    }
`;
