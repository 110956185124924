import SectionHeader from "./SectionHeader";
import React from "react";
import styled from "styled-components/macro";
import SvgProjects from "./icons/icons/Projects";
import ProjectEntry from "./ProjectEntry";
import {projectData} from "../data/projects";

const Projects = () => {
    return (
        <StyledProjects>
            <SectionHeader icon={<SvgProjects/>} headerText={"Projekte"}/>
            {projectData.map(({timeRange, title, description, link, hide}) => <ProjectEntry
                key={timeRange}
                timeRange={timeRange}
                title={title}
                description={description}
                link={link}
                hide={hide}
            />)}
        </StyledProjects>
    );
};
export default Projects;

const StyledProjects = styled.div`
    display: none
`;
