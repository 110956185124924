import SectionHeader from "./SectionHeader";
import SectionEntry from "./SectionEntry";
import React from "react";
import SvgWork from "./icons/icons/Work";
import styled from "styled-components/macro";
import {workData} from "../data/work";

const Work = () => {
    return (
        <div>
            <SectionHeader icon={<SvgWork/>} headerText={"Beruflicher Werdegang"}/>
            {workData.map(({title, subTitle, timeRange, description,bullets, hide,hideForPrint, techStack}) => <SectionEntry
                key={timeRange}
                timeRange={timeRange}
                title={title}
                subTitle={subTitle}
                description={description}
                bullets={bullets}
                hideForPrint={hideForPrint}
                hide={hide}
                techStack={techStack}
            />)
            }
        </div>
    );
};
export default Work;

