export type WorkData = {
    timeRange: string,
    title: string,
    subTitle: string,
    description?: string,
    bullets?: string[],
    hideForPrint?: true,
    hide?: true
    techStack?: string[]
}

export const workData: WorkData[] = [{
    title: 'Senior Fullstack Developer',
    subTitle: 'Freelancer @Die Mobiliar',
    timeRange: 'Juni 2024 – heute',
    description: 'Dieses Projekt digitalisiert den Versicherungsabschluss und ermöglicht eine nahtlose Online-Abwicklung von der Angebotserstellung bis zur Vertragsbestätigung. Als Fullstack-Entwickler habe ich das NX-Monorepo modernisiert, neue Applikationen mit SignalStore umgesetzt und bestehende Anwendungen in die Cloud migriert. Im Backend habe ich mit Java und Kotlin gearbeitet sowie Tests mit JUnit und Cucumber erstellt. Für Qualitätssicherung und Stabilität setzen wir auf Jest, Playwright und Docker. Durch diese Massnahmen wurde die Plattform effizienter, skalierbarer und zukunftssicher.',
    bullets: [
        "Modernisierung des NX-Monorepos zur Verbesserung der Skalierbarkeit und Effizienz",
        "Entwicklung neuer Applikationen mit SignalStore für eine flexible Architektur",
        "Migration bestehender Anwendungen in die Cloud, um die Systemkomplexität zu reduzieren",
        "Backend-Entwicklung mit Java und Kotlin, inklusive Erweiterungen und Optimierungen",
        "Implementierung automatisierter Tests mit Jest, Playwright, JUnit und Cucumber",
    ],
    techStack: ["TypeScript",
        "Angular",
        "NX",
        "SignalStore",
        "Java",
        "Kotlin",
        "JUnit",
        "Cucumber",
        "Jest",
        "Playwright",
        "Docker",
        "Gitlab CI/CD"]
}, {
    timeRange: "Jan. 2019 – Juni 2024",
    title: "Senior Software Engineer",
    subTitle: 'Freelancer @SRF Schweizer Radio Fernsehen',
    description: 'Die SRF Mediathek (Play) ist eine Streaming-Plattform mit Livestreams, Videos und weiteren Funktionen für alle Sprachregionen der Schweiz. Dafür wurde eine zentrale Codebasis entwickelt, die es ermöglicht, regionale Besonderheiten flexibel abzubilden. Zudem wurde ein mandantenfähiges CMS geschaffen, mit dem Inhalte für verschiedene Webseiten, Apps und TVos verwaltet und kuratiert werden. Ein Integrationslayer sorgt für die Vereinheitlichung von Daten aus unterschiedlichen Systemen, während ein optimierter Image-Service die Ladezeiten verkürzt, moderne Bildformate bereitstellt und angepasste Auflösungen ermöglicht.',
    bullets: [
        "Entwicklung einer hochkonfigurierbaren Anwendung mit 'Server Side Rendering' mit TypeScript, Styled-Components und React",
        "Berücksichtigung von Accessibility, SEO und verständlichem UX in mehreren Sprachen",
        "Sicherstellung der Qualität durch PaaS-Nutzung, Testlink-Generierung und verschiedene Tests",
        "Konzeption und Umsetzung eines CMS mit TypeScript, Styled-Components und React",
        "Implementierung automatisierter Content-Steuerung, Benutzerrechte und Metadatenmodifikation",
        "Integration des CMS zur Verwaltung von Inhalten für andere Applikationen",
        "Entwicklung eines Integrationslayers mit Kotlin/Java, Micronaut und Postgres",
        "Umsetzung eines Image-Services mit AWS und Rokka für schnellere Ladezeiten, moderne Bildformate und optimierte Auflösungen",
        "Betreuung eines Lehrlings pro Jahr während dessen Abschlussjahres",
        "Fachvorgesetzter für die praktische Abschlussarbeit mit Fokus auf Anleitung und Förderung",
        "Migration einer JavaScript-Applikation nach TypeScript"
    ],
    techStack: ['TypeScript', 'React', 'Styled-Components', 'Next.js', 'Zod', 'Kotlin', 'Java', 'Micronaut', 'Postgres', 'AWS', 'Rokka', 'Heroku', "AWS"]
}, {
    timeRange: "Sept. 2021 – Sept. 2024",
    title: "MAS Assistenz",
    subTitle: 'Assistenz des MAS "Software Engineering" bei der Ostschweizer Fachhochschule (früher HSR).'
}, {
    timeRange: "Juni 2016 – Jan. 2019",
    title: "Senior Frontend Engineer",
    subTitle: 'Helsana AG',
    description: 'Im Rahmen mehrerer Projekte war ich verantwortlich für die Entwicklung und Optimierung von interaktiven Weblösungen, die Privatkunden und Versicherungsberatern eine einfache Verwaltung von sensiblen Daten ermöglichen. Dabei übernahm ich eine leitende Rolle sowohl im Operations-Bereich als auch in der Frontend-Entwicklung. Die Projekte beinhalteten die Umsetzung eines BizDevOps-Ansatzes, die Verbesserung der Testabdeckung, die Einführung von Continuous Delivery und die Entwicklung eines neuen Responsive Designs. Zudem führte ich die Umstellung von einem alten JSP-Stack auf das moderne Angular-Framework durch und setzte mich intensiv mit Performanceoptimierungen und Browser-Kompatibilität auseinander.',
    bullets: [
        "Lead im Operations-Teil und in der Frontend-Entwicklung im Rahmen des BizDevOps-Ansatzes",
        "Aufbau und Implementierung des End-To-End-Testsetups mit Protractor und Selenium-Grid",
        "Weiterentwicklung von Kernfunktionalitäten und Qualitätsprüfungen mit Karma und Jasmine",
        "Umsetzung einer Continuous Delivery Pipeline mit Gitlab-Runner und Gitflow Branching Model",
        "Performancevergleichsmessungen mit Gatling zur Erkennung von Engpässen",
        "Überarbeitung des Kundenportals von statischem Design zu einem neuen Responsive Design",
        "Umstellung des alten JSP-Stacks auf das moderne Angular-Framework als Lead-Entwickler",
        "Entwicklung einer interaktiven Weblösung zur Erstellung von Offerten für Krankentaggeld und Unfallversicherung",
        "Mitarbeit an der Entwicklung von Microservices im Backend mit Spring Boot"
    ],
    techStack: ["TypeScript",
        "Angular",
        "Protractor",
        "Selenium-Grid",
        "Karma",
        "Jasmine",
        "Gitlab CI/CD",
        "Gatling",
        "Responsive Design",
        "Kotlin",
        "Spring Boot",
        "RxJS"
    ]
}, {
    timeRange: "Jan. 2015 – Mai 2016",
    title: "Informatik Consultant",
    subTitle: 'ERNI Consulting AG',
    description: 'Mitentwicklung eines Web-Portals für Businesskunden zur übersichtlichen Verwaltung von Rechnungen, Wartungen und Services. Die mandantenfähige Applikation wurde im Frontend mit Angular.js und im Backend mit Java umgesetzt. Mein Fokus lag auf der Entwicklung unter Berücksichtigung bestehender Performance- und Qualitätsstandards. Dabei habe ich Optimierungen vorgenommen und moderne Tools integriert, um die Nutzererfahrung und Skalierbarkeit zu verbessern. Lombok wurde im Backend zur Reduzierung von Boilerplate-Code eingesetzt.',
    bullets: [
        "Entwicklung eines Web-Portals zur Verwaltung von Rechnungen, Wartungen und Services",
        "Frontend-Optimierungen mit Angular.js unter Berücksichtigung von Performance- und Qualitätsstandards",
        "Reduzierung von Boilerplate-Code im Backend mit Lombok",
        "Optimierung der Skalierbarkeit und Nutzererfahrung"
    ],
    techStack: ['Angular.js', 'Java', 'Lombok']
}, {
    timeRange: "Aug. 2010 – Juni 2011",
    title: "Betriebselektriker",
    subTitle: 'Ferag',
    description: 'Montage und Inbetriebnahme von Elektro-Anlagen, Unterhalt und Reparaturen an Produktionsmaschinen',
    hide: true
}]
