import React, {ReactNode} from "react";

import styled from "styled-components/macro";

type Props = { children: ReactNode };

const MainLayout = ({children}: Props) => (
    <StyledLayout>{children}</StyledLayout>
);

export default MainLayout;

const StyledLayout = styled.div`
`;
